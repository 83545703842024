import { DataResponseError, DataResponseReturnType } from '@/common/types';
import { Configuration, EmailName, Message } from '@nylas/core';
import dayjs from 'dayjs';
import { RRule } from 'rrule';
import i18next from '@/utils/i18n';
import sanitizeHtml from 'sanitize-html';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const sanitizeOptions = {
  textFilter: function (text) {
    return text.replace(/&amp;/g, '&'); // Replace encoded '&' back to its raw form
  },
};

export function getTimezoneOffset(timezone: string) {
  return dayjs().tz(timezone).format('Z');
}

export function sanitize(text: string) {
  return sanitizeHtml(text, sanitizeOptions);
}
export function format(first: string, middle: string, last: string): string {
  return (first || '') + (middle ? ` ${middle}` : '') + (last ? ` ${last}` : '');
}

// Utility function to remove undefined values from an object
export function removeUndefined(obj: any) {
  return Object.keys(obj).reduce((acc: { [key: string]: any }, key) => {
    if (obj[key] !== undefined) {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}

export function debug(...args: any[]) {
  if (window && window.localStorage && window.localStorage.getItem('debug')) {
    console.group(...args);
    console.trace();
    console.groupEnd();
  }
}

export function error(...args: any[]) {
  console.error(...args);
}

export function uniqueID() {
  return Math.random().toString(36).substr(2, 9);
}

export function formatEventName(propKey: string) {
  return propKey.toString().replace(/^on/, '');
}

// Utility function to format a date as a time if it is today, or a short
// date format if it is not toda, using the new Intl API. For example:
// If the date is today, it will return "12:00 PM"
// If the date is not today, it will return "Jan 4, 23"
export function formatDateTime(dateTimestamp: number) {
  const date = new Date(dateTimestamp * 1000);
  const now = new Date();
  const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  if (date >= today) {
    // If the day is not today, we return the month and day (e.g. Jan 4)
    const formatter = new Intl.DateTimeFormat('default', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
    return formatter.format(date);
  } else {
    // If the day is today, we only return the time (e.g. 12:00 PM) without
    // the month and day
    const formatter = new Intl.DateTimeFormat('default', {
      month: 'short',
      day: 'numeric',
    });
    return formatter.format(date);
  }
}

export function formatRecipient(recipient: EmailName) {
  return recipient.name ? `${recipient.name} - ${recipient.email}` : recipient.email;
}

// Formats the particpants of a thread into a string
export function formatParticipants(participants: EmailName[], meEmail: string = '', messageCount: number = 1, max: number = 3) {
  const me = participants.find(p => p.email?.toLowerCase() === meEmail.toLowerCase());
  const others = participants.filter(p => p.email?.toLowerCase() !== meEmail.toLowerCase());
  const othersCount = others.length;
  const othersString = others
    .slice(0, max)
    .map(r => r.name || r.email)
    .join(', ');
  if (othersCount === 0) {
    return me ? 'me' : '';
  } else if (othersCount === 1) {
    return me && messageCount > 1 ? `me, ${othersString}` : othersString;
  } else if (othersCount === 2) {
    return me ? `me, ${othersString}` : `${othersString}`;
  } else {
    return me ? `me, ${othersString}, +${othersCount - max}` : `${othersString}, +${othersCount - max}`;
  }
}

// https://blog.webdevsimplified.com/2020-07/relative-time-format/
const DIVISIONS: Array<{
  amount: number;
  name: Intl.RelativeTimeFormatUnit;
}> = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
];

export const formatTimeAgo = (date: Date) => {
  const formatter = new Intl.RelativeTimeFormat(undefined, {
    numeric: 'auto',
  });
  const now = new Date();
  let duration = (date.getTime() - now.getTime()) / 1000;

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
};

export function getLastDayOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
}

export function getFirstDayOfMonth(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getFirstHourOfDay(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0);
}

export function isSameDay(date1: Date, date2: Date) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth() && date1.getDate() === date2.getDate();
}

export function isSameMonth(date1: Date, date2: Date) {
  return date1.getFullYear() === date2.getFullYear() && date1.getMonth() === date2.getMonth();
}

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
}

export function formatFolderName(folderName: string) {
  return folderName.replace('CATEGORY_', '').replace(/_/g, ' ');
}

export function detectHTMLOrPlainText(html: string) {
  const regex = /<[^>]*>/g;
  return regex.test(html) ? 'html' : 'plain';
}

export function detectIfMessageIsHTML(message: Message) {
  const headers = message.headers;
  const contentType = (headers && headers.find(h => h.name === 'Content-Type')?.value) || '';
  const contentDisposition = (headers && headers.find(h => h.name === 'Content-Disposition')?.value) || '';

  // If the content type is HTML, then we know it's HTML
  if (contentType.includes('text/html')) {
    return true;
  }

  // If the content disposition is inline, then we know it's HTML
  if (contentDisposition.includes('inline')) {
    return true;
  }

  // If the body is HTML, then we know it's HTML
  if (message.body && detectHTMLOrPlainText(message.body) === 'html') {
    return true;
  }

  // Otherwise, we assume it's plain text
  return false;
}

export function parsePlainTextToHTML(text: string) {
  const paragraphs = text.split('\n').filter(line => line.trim().length > 0);
  const wrappedParagraphs = paragraphs.map(paragraph => `<p>${paragraph}</p>`);
  let body = wrappedParagraphs.join('');

  // Replace links with anchor tags
  const regex = /((?:https?|ftp):\/\/[^\s]+)/g;
  body = body.replace(regex, '<a href="$1">$1</a>');

  return body;
}

export function htmlToPlainText(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

export function getStartHourOfDay(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0);
}

export function getEndHourOfDay(date: Date) {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23);
}

// Zero pad a number to two digits
export function get24HourTime(date: Date) {
  return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
}

export function getUserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const getEventRecurrence = (recurrence: any): string | null => {
  if (recurrence.includes('RRULE:')) {
    recurrence = recurrence.replace('RRULE:', '');
    return RRule.fromString(recurrence).toText();
  }
  return RRule.fromString(recurrence).toText();
};

// Validate time format part
export const validateTimeFormatInput = text => {
  if (text === '') {
    return true;
  }
  const regexPattern = /^(0[0-9]|1[0-2]|^[0-9])(:[0-5][0-9][ap]m?|:[0-5][0-9]|:[0-5]|:)?$/i;
  return regexPattern.test(text);
};
// Validate exact time format
export const validateExactTimeFormat = input => {
  const regex = /^(0[1-9]|1[0-2]):([0-5][0-9])(am|pm)$/i;
  return regex.test(input);
};

export function roundToNearest15Minutes(time: dayjs.Dayjs | null = null) {
  let currentTime = dayjs(); // Get the current time
  if (time) {
    currentTime = time;
  }

  // Calculate the remainder when dividing the minutes by 15
  const minuteRemainder = currentTime.minute() % 15;

  let roundedTime;

  if (minuteRemainder !== 0) {
    // Round up to the next 15-minute interval
    roundedTime = currentTime.add(15 - minuteRemainder, 'minute');
  } else {
    // Keep the current time as it is
    roundedTime = currentTime;
  }

  return roundedTime;
}

export function autocompleteTimeFormat(input) {
  const currentTime = dayjs(); // Get the current time

  let completedTime = '';

  // Extract the hour and minute components
  const [inputHour, inputMinuteWithAmPm] = input.split(':');
  // Autocomplete the hour component
  if (inputHour.length === 1) {
    completedTime += '0' + inputHour;
  } else if (inputHour.length === 2) {
    completedTime += inputHour;
  }

  completedTime += ':';

  // Autocomplete the minute component
  if (inputMinuteWithAmPm === undefined || inputMinuteWithAmPm == '') {
    completedTime += '00';
  } else {
    if (inputMinuteWithAmPm.length === 1) {
      completedTime += inputMinuteWithAmPm + '0';
    } else {
      completedTime += inputMinuteWithAmPm;
    }
  }

  // Set the am/pm indicator based on the provided input
  const amPmIndicator = completedTime.slice(-1); // Extract the am/pm indicator from input

  if (amPmIndicator === 'a') {
    completedTime += 'm';
  }
  if (amPmIndicator === 'p') {
    completedTime += 'm';
  }

  // Set the am/pm indicator based on the current time if no indecator present inside input
  if (completedTime.slice(-1) !== 'm') {
    if (completedTime.split(':')[0] == '00') {
      completedTime += 'am';
    } else {
      completedTime += currentTime.format('a');
    }
  }

  return completedTime;
}

export function formatTime(time: string) {
  return dayjs(time, 'hh:mma');
}

export function formatTimezone(date: Date, timezone: string) {
  return dayjs(date).tz(timezone).format('hh:mm A'); // 12-hour format with AM/PM
}

export function convertTo12HourFormat(timeStr: string) {
  // Split the time into hours and minutes
  const [hoursInitial, minutes] = timeStr.split(':').map(num => parseInt(num, 10));
  let hours = hoursInitial;

  // Determine AM or PM
  const ampm = hours >= 12 ? 'pm' : 'am';

  // Convert hours from 24-hour to 12-hour format
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'

  // Format hours and minutes to ensure two digits
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');

  // Construct the 12-hour format time string
  return `${formattedHours}:${formattedMinutes}${ampm}`;
}

export function convertTo24HourFormat(timeStr: string) {
  const [time, modifier] = timeStr.split(/(am|pm)/i);
  const [hoursInitial, minutes] = time.split(':').map(num => parseInt(num, 10));
  let hours = hoursInitial;

  if (modifier.toLowerCase() === 'pm' && hours < 12) {
    hours += 12;
  } else if (modifier.toLowerCase() === 'am' && hours === 12) {
    hours = 0;
  }

  return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

export function timeToMinutes(time) {
  const [hours, minutesPart] = time.match(/(\d+):(\d+)(am|pm)/).slice(1);
  let minutes = parseInt(hours, 10) * 60 + parseInt(minutesPart, 10);
  if (time.includes('pm') && parseInt(hours, 10) < 12) minutes += 12 * 60;
  if (time.includes('am') && parseInt(hours, 10) === 12) minutes -= 12 * 60;
  return minutes;
}

export function minutesToTime(minutes) {
  const h = Math.floor(minutes / 60);
  const m = minutes % 60;
  return `${h}:${m < 10 ? '0' : ''}${m}`;
}

export function getBrowser() {
  const ua = navigator.userAgent;

  // Check for Chrome (also in Edge and Opera)
  const isChrome = /Chrome/.test(ua) && /Google Inc/.test(navigator.vendor);

  // Check for Safari (also in Chrome and Opera)
  const isSafari = /Safari/.test(ua) && /Apple Computer/.test(navigator.vendor);

  // Check for Firefox
  const isFirefox = /Firefox/.test(ua);

  // Check for Internet Explorer
  const isIE = /Trident/.test(ua);

  // Check for Edge (the legacy version)
  const isEdgeLegacy = /Edge/.test(ua);

  // Check for Edge (the Chromium-based version)
  const isEdgeChromium = isChrome && /Edg/.test(ua);

  if (isEdgeChromium) return 'Edge (Chromium)';
  if (isChrome) return 'Chrome';
  if (isSafari) return 'Safari';
  if (isFirefox) return 'Firefox';
  if (isIE) return 'Internet Explorer';
  if (isEdgeLegacy) return 'Edge (Legacy)';

  return 'Unknown';
}

/**
 * Utility function to create a response object with data and error.
 * @param data any The data to return.
 * @param error DataResponseError The error to return.
 */
export function dataResponse<T = any>(data: T, error: DataResponseError | null = null): DataResponseReturnType<T> {
  if (error) {
    return [null, error];
  }
  return [data, null];
}

/**
 * Utility function to parse a preview link with placeholders.
 * @param link string A preview link with placeholders.
 * @param config Partial<Configuration> The configuration object to use to replace the placeholders.
 * @returns string The preview link with the placeholders replaced.
 */
export function parsePreviewLink(link: string, config?: Partial<Configuration>) {
  // Replacements
  const replacements = {
    'config.id': config?.id,
    'id': config?.id,
    'slug': config?.slug,
  };

  // Replace the placeholders with the actual values
  Object.keys(replacements).forEach(key => {
    if (replacements[key]) {
      link = link.replace(`{${key}}`, replacements[key]);
    }
  });

  return link;
}

export function isNonPrintableKey(event) {
  // Check for special keys like backspace, enter, escape, arrow keys, function keys, etc.
  if (
    event.keyCode < 48 || // Before 0
    (event.keyCode > 90 && event.keyCode < 96) || // Between Z and numpad 0
    (event.keyCode > 111 && event.keyCode < 186) || // Between numpad 9 and semi-colon (;)
    event.keyCode > 222 || // After single quote (')
    event.keyCode === 32 || // Spacebar
    event.keyCode === 91 || // Left Window key or left ⌘ key
    event.keyCode === 92 || // Right Window key or right ⌘ key
    event.keyCode === 93 || // Select key / Menu key or right ⌘ key
    (event.keyCode >= 112 && event.keyCode <= 123) || // Function (F1-F12) keys
    (event.keyCode >= 37 && event.keyCode <= 40) // Arrow keys
  ) {
    return true;
  }

  // Check for Ctrl, Alt, Shift, CapsLock, etc., by ignoring if any of these are pressed
  if (event.ctrlKey || event.altKey || event.metaKey) {
    return true;
  }

  // Check if Shift is combined with a number or special character
  const shiftSpecialChars = [
    48,
    49,
    50,
    51,
    52,
    53,
    54,
    55,
    56,
    57, // Numbers (0-9)
    186,
    187,
    188,
    189,
    190,
    191,
    192,
    219,
    220,
    221,
    222, // Special characters (;=,-./` and []\')
  ];

  if (event.shiftKey && shiftSpecialChars.includes(event.keyCode)) {
    return false;
  }

  // If none of the above, it's a character, number, or special character typed without shift
  return false;
}

export function addDaysToCurrentDate(startDate, days) {
  const currentDate = new Date(startDate);
  currentDate.setDate(currentDate.getDate() + days);
  currentDate.setHours(23); // Sets the hour to 11 PM
  currentDate.setMinutes(59); // Sets the minute to 59
  currentDate.setSeconds(0); // Optionally, sets the seconds to 0
  currentDate.setMilliseconds(0); // Optionally, sets the milliseconds to 0
  return currentDate;
}

export function addMinutesToCurrentTime(startDate, minutes) {
  const currentDate = new Date(startDate);
  currentDate.setMinutes(currentDate.getMinutes() + minutes);
  return currentDate;
}

export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function mergeDeep(target, source) {
  if (Array.isArray(target) && Array.isArray(source) && target !== source) {
    // Custom handling for participants array
    if (target.some(item => item.is_organizer) && source.some(item => item.is_organizer)) {
      // Ensure all base participants are not organizers if there's an organizer in the selected config
      target = target.map(participant => ({ ...participant, is_organizer: false }));
    } else if (!source.some(item => item.is_organizer)) {
      // Make sure there is at least one organizer in the base if none in selected
      let foundOrganizer = false;
      target = target.map(participant => {
        if (!foundOrganizer) {
          foundOrganizer = true;
          return { ...participant, is_organizer: true };
        }
        return participant;
      });
    }
    // Merge each element of the arrays by index
    return target
      .map((item, index) => {
        return source[index] ? mergeDeep(item, source[index]) : item;
      })
      .concat(source.slice(target.length));
  } else if (isObject(target) && isObject(source)) {
    const output = Object.assign({}, target);
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)) {
          output[key] = source[key];
        } else {
          output[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        output[key] = source[key];
      }
    });
    return output;
  }
  return source;
}

export function convertMinutesToHoursAndMinutes(minutes: number): string {
  if (minutes < 60) {
    return `${minutes} ${t('time.minutes')}`;
  }
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  const hoursText = t(`time.${hours > 1 ? 'hours' : 'hour'}`);
  const minutesText = remainingMinutes ? `${remainingMinutes} ${t(`time.${remainingMinutes > 1 ? 'minutes' : 'minute'}`)}` : '';
  return `${hours} ${hoursText} ${minutesText}`;
}

export function checkForMissingSlots(slots: string[], host: HTMLElement) {
  slots.forEach(slot => {
    const slotElement = host.shadowRoot?.querySelector(`slot[name="${slot}"]`) as HTMLSlotElement;
    const slotNodes = slotElement?.assignedNodes();
    if (slotNodes && slotNodes.length === 0) {
      console.warn(`${host.nodeName} No content provided for slot="${slot}".`);
    }
  });
}

export function compactStringToUUIDs(compactString: string) {
  // Decode the Base64 string to a Buffer
  const buffer = Buffer.from(compactString, 'base64');

  // Extract each UUID's bytes (16 bytes each)
  const uuidBytes1 = buffer.slice(0, 16);
  const uuidBytes2 = buffer.slice(16, 32);
  // Extract the remainder as the salt
  const salt = buffer.slice(32);

  // Function to convert a buffer to UUID string format
  function bufferToUUID(buffer) {
    const hex = buffer.toString('hex');
    return `${hex.slice(0, 8)}-${hex.slice(8, 12)}-${hex.slice(12, 16)}-${hex.slice(16, 20)}-${hex.slice(20)}`;
  }

  // Convert buffers to UUID strings
  const uuid1 = bufferToUUID(uuidBytes1);
  const uuid2 = bufferToUUID(uuidBytes2);

  // Convert salt buffer to URL-safe base64 without padding
  const b64EncodedSalt = salt.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

  return [uuid1, uuid2, b64EncodedSalt];
}

export const t = key => {
  return i18next.t(key);
};

export const capitalizeFirstLetter = (str: string) => {
  if (typeof str !== 'string') return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const translateMonth = (month: string) => {
  return t(`months.${month}`);
};

export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidImage = (url: string) => {
  return new Promise((resolve, reject) => {
    if (url === '') return resolve(true);

    const img = new Image();
    img.src = url;
    img.onerror = () => {
      reject(false);
    };
    img.onload = () => {
      resolve(true);
    };
  });
};
