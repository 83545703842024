import i18next from 'i18next';
import * as enTranslation from '../locales/en.json';
import * as frTranslation from '../locales/fr.json';
import * as esTranslation from '../locales/es.json';
import * as jaTranslation from '../locales/ja.json';
import * as deTranslation from '../locales/de.json';
import * as svTranslation from '../locales/sv.json';
import * as zhCnTranslation from '../locales/zh-cn.json';
import * as nlTranslation from '../locales/nl.json';

i18next.init({
  lng: 'en', // Set default language
  resources: {
    en: {
      translation: enTranslation,
    },
    fr: {
      translation: frTranslation,
    },
    es: {
      translation: esTranslation,
    },
    ja: {
      translation: jaTranslation,
    },
    de: {
      translation: deTranslation,
    },
    sv: {
      translation: svTranslation,
    },
    zh: {
      translation: zhCnTranslation,
    },
    nl: {
      translation: nlTranslation,
    },
    // Add more languages as needed
  },
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
